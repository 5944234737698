import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',[_c('template',{slot:"content"},[_c(VApp,{attrs:{"id":"login"}},[_c(VMain,[_c(VContainer,{staticClass:"fill-height"},[_c(VRow,{staticClass:"main",attrs:{"justify":"center"}},[_c(VSpacer,{staticClass:"d-none d-sm-flex"}),_c(VCol,{staticClass:"form",attrs:{"cols":"12","sm":"12","md":"7"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/logo-form.png")}}),_c('h2',{staticClass:"form-title"},[_vm._v("Plataforma Legal")]),_c('p',{staticClass:"text-large"},[_vm._v("Iniciar sesión")]),_c('form',{attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c(VTextField,{attrs:{"label":"Usuario","outlined":"","rules":_vm.usernameRules},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"label":"Contraseña","outlined":"","type":"password","rules":_vm.passwordRules},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VBtn,{staticClass:"login-btn default-btn",attrs:{"type":"submit","large":"","color":"primary","raised":"","depressed":"","disabled":_vm.isLoading,"loading":_vm.isLoading}},[_vm._v(" Iniciar sesión ")])],1)],1)])],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }