<template>
  <BaseLayout>
    <template slot="content">
      <v-app id="login">
        <v-main>
          <v-container class="fill-height">
            <v-row justify="center" class="main">
              <v-spacer class="d-none d-sm-flex" />
              <v-col
                cols="12"
                sm="12"
                md="7"
                class="form"
              >
                <img src="@/assets/images/logo-form.png" class="logo">
                <h2 class="form-title">Plataforma Legal</h2>
                <p class="text-large">Iniciar sesión</p>
                <form @submit.prevent="submit" lazy-validation>
                  <v-text-field label="Usuario" v-model="username" outlined :rules="usernameRules"/>
                  <v-text-field label="Contraseña" v-model="password" outlined type="password" :rules="passwordRules"/>
                  <v-row align="center" justify="center">
                    <v-btn
                      type="submit"
                      class="login-btn default-btn"
                      large
                      color="primary"
                      raised
                      depressed
                      :disabled="isLoading"
                      :loading="isLoading"
                    >
                      Iniciar sesión
                    </v-btn>
                  </v-row>
                </form>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </template>
  </BaseLayout>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import BaseLayout from '@/layouts/BaseLayout'
import { colors } from '@/utils/colors'

export default {
  name: 'Login',
  components: {
    BaseLayout
  },

  data () {
    return {
      username: '',
      password: '',
      isLoading: false,
      usernameRules: [
        value => !!value || 'El nombre de usuario es requerido'
      ],
      passwordRules: [
        value => !!value || 'La contraseña es requerida'
      ]
    }
  },

  created () {
    const token = localStorage.getItem('legalToken')
    const profile = localStorage.getItem('profile')

    if (token && profile) {
      this.$router.push('/portal')
    }
  },

  methods: {
    ...mapMutations({
      setSnackbar: 'SET_SNACKBAR',
      setNavNotificationNumber: 'SET_NAV_NOTIFICATIONS_NUMBER'
    }),
    ...mapActions(['getToken']),

    submit () {
      this.isLoading = true
      const payload = { username: this.username, password: this.password }
      this.getToken(payload)
        .then(response => {
          const token = response.data.access
          const profile = response.data.groups[0] || 'Client'
          const name = response.data.name
          const area = response.data.areas
          const country = response.data.country || 'CO'
          const iduser = response.data.iduser
          this.setNavNotificationNumber(response.data.notifications)
          /* localStorage */
          localStorage.setItem('notificationNumber', response.data.notifications)
          localStorage.setItem('environment', response.data.environment)
          localStorage.setItem('legalToken', token)
          localStorage.setItem('profile', profile)
          localStorage.setItem('area', area)
          localStorage.setItem('name', name)
          localStorage.setItem('country', country)
          localStorage.setItem('user', iduser)
          this.isLoading = false
          this.$router.push('/portal')
        })
        .catch(error => {
          this.isLoading = false
          this.setSnackbar({
            text: error.response.data.detail,
            timeout: 3000,
            showing: true,
            color: colors.primary
          })
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">

  /* Background management */
  #login {
    background: url(~@/assets/images/background.png) no-repeat 0 0 fixed;
    background-size: 100% 100%;

    /* Main card */
    .main {
      position: absolute;
      left: 12.22%;
      right: 12.22%;
      top: 13.58%;
      bottom: 13.58%;
      height: auto;

      background-image: url(~@/assets/images/login.png), url(~@/assets/images/circles.png);
      background-position: 0 0, top -20px right -20px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
      background-size: 40% 100%, auto;
      border-radius: 16px;
    }

    .logo {
      margin-bottom: 17px;
      width: 230px;
      height: 55px;
    }

    .form {
      padding: 100px 128px 10px 112px !important;
    }

    .form-title {
      margin-bottom: 32px;
    }

    .login-btn {
      margin: 10px 0px;
    }
  }

  @media screen and (max-width: 1380px) {
    #login {
      background: url(~@/assets/images/background.png) no-repeat fixed;
      background-size: cover;

      .form {
        transform: scale(0.8);
        padding: 10px 58px 10px 12px !important;
      }
    }
  }

  @media screen and (max-width: 940px) {
    #login {
      background: url(~@/assets/images/background.png) no-repeat fixed;
      background-size: cover;

      .main {
        background-image: url(~@/assets/images/circles.png);
        background-position: top -20px right -20px;
        background-color: #FFFFFF;
        background-size: auto;
      }

      .form {
        padding: 0px 30px 0px 5px !important;
      }

      .logo {
        margin-top: 0;
      }
    }
  }

  @media screen and (max-width: 600px) {
    #login {
      background: url(~@/assets/images/background.png) no-repeat fixed;
      background-size: cover;

      .main {
        background: #FFFFFF;
      }
    }
  }

  @media screen and (max-height: 800px) {
    #login {
      background: url(~@/assets/images/background.png) no-repeat fixed;
      background-size: cover;

      .form {
        transform: scale(0.8);
        padding: 10px 58px 10px 12px !important;
      }
    }
  }

</style>
