<template>
  <v-app id="layout">
    <slot name="navbar" />
    <v-main class="content">
      <slot name="content" />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'BaseLayout',
  components: { /* Navbar */ },
  data () {
    return {
      links: [
        {
          title: 'Mis Contratos',
          link: '/contracts'
        },
        {
          title: 'Plantillas',
          link: '/templates'
        },
        {
          title: 'Configuraciones',
          link: '/configurations'
        }
      ],
      name: 'Anderson Mosquera',
      area: 'Área Legal',
      items: [
        {
          title: 'Cerrar Sesión'
        }
      ]
    }
  }
}
</script>

<style>

#layout {
  background-color: var(--v-background-base);
}

</style>
